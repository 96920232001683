import axiosV2, { apiPost } from '../core/http/axios_api_v2';

export default {


    saveComment(comment, customerId, extraData) {


        if (extraData.createTask) {

            let task = planup.$handlers.tasksHandler.generateLocalEmptyTask();
            task.description = 'מעקב: \n\n' + comment;
            task.name = 'מעקב: \n\n' + comment;



            planup.$handlers.customerHandler.getCustomer(customerId).then(customer => {

                task.customer = customer;
                planup.$root.$emit('openTaskModal', task)

            })



        }


        return apiPost('tracking-log', { comment, customerId, extraData }).then(log => {


            let customerPage = planup.$globals.findComponent('CustomerPage');

            if (customerPage.customerId == customerId) {
                log.value = JSON.parse(log.value);
                customerPage.customerLog.unshift(log);
            }


            return log;

        })

    },




    createFreeTextLog(text, customerId) {
        return apiPost('tracking-log', { text, customerId }).then(response => response.data)

    },


    actionToText(action, table) {

        if (action == 0 && table == 'events')
            return 'פניה חדשה';

        switch (action) {
            case 0:
                return 'יצירת';
            case 1:
                return 'עריכת';
            case 2:
                return 'הערה';
        }
    },



    getAll() {
        return axiosV2('tracking-log').then(response => response.data)
    },

    getCustomerHistory(customerId, eventId) {
        // return axiosV2(`tracking-log/customerHistory/${customerId}?eventId=${eventId?eventId:''}`).then(response => response.data)
        return axiosV2(`tracking-log/customerHistory/${customerId}`).then(response => response.data)

    },



    printSingleValue(field, value) {



        // console.log(field);



        switch (field) {


            case 'leadSources':
                return value.map(source => source.name).join(', ');

            case 'planupSystemStatus':
                return planup.$handlers.eventHandler.eventStatusIndicatorHTML(value);



            case 'terms':
                return value.name;

            case 'specificRequestedDates':
                let specificRequestedDatesText = '';
                value.map(d => specificRequestedDatesText += planup.$globals.niceDate(d) + ' ')
                return specificRequestedDatesText;

            case 'requestedDateRange':
                return `${value.rangeStart} - ${value.rangeEnd}`

            case 'products':
                return '...';


            case 'notifyMailAddress':
                let notifyMailAddressText = '';
                value.map(email => notifyMailAddressText += `${email.name} ${email.email} `)
                return notifyMailAddressText;

            case 'meetingType':
            case 'place':
            case 'eventClass':
                return value.name;
            case 'crewMembers':
            case 'contacts':
                return planup.$globals.concatContactNames({ contacts: value });
            case 'date':
                return planup.$globals.niceDate(value);
            case 'planupMeetingType':
                return value.name;

        }

        return value;

    },

    logTableLink(table, value) {



        switch (table) {
            case 'events':
                return `/customer/${value.customerId}?eventId=${value.entityId}`
            case 'customer_contact':
                return 'איש קשר';
            case 'document':
                return 'מסמך';
            case 'appointments':
                return 'פגישה';
        }

        return table;

    },


    fieldToHebrew(field) {

        switch (field) {

            case 'firstName':
                return 'שם פרטי';
            case 'lastName':
                return 'שם משפחה';
            case 'name':
                return 'שם';
            case 'date':
                return 'תאריך';
            case 'reservePercentage':
                return 'אחוז רזרבה';
            case 'products':
                return 'מוצרים/שרותים';
            case 'crewMembers':
                return 'צוות';
            case 'notes':
                return 'הערות';
            case 'otherVendors':
                return 'ספקים אחרים';
            case 'terms':
                return 'תנאי הסכם';
            case 'requestedDateRange':
                return 'טווח תאריכים מבוקש';
            case 'specificRequestedDates':
                return 'תאריכים מבוקשים';
            case 'estimatedNumberOfGuests':
                return 'מספר אורחים'
            case 'place':
                return 'מקום האירוע'
            case 'leadSources':
                return 'מקור הפניה';
            case 'content':
                return 'תוכן';
            case 'title':
                return 'שם';
            case 'planupMeetingType':
                return 'סוג פגישה';
            case 'eventClass':
                return 'סוג אירוע';
            case 'taxDocumentCustomerKey':
                return 'מספר לקוח חשבונאי';
            case 'contacts':
                return 'אנשי קשר';
            case 'email':
                return 'מייל';
            case 'phone':
                return 'טלפון';
            case 'meetingType':
                return 'סוג פגישה'
            case 'notifyMailAddress':
                return 'כתובת מייל'
            case 'teudatZehut':
                return 'תעודת זהות'
            case 'address_street':
                return 'רחוב'
            case 'address_number':
                return 'מספר בית'
            case 'address_city':
                return 'עיר'
            case 'address_mikud':
                return 'מיקוד'
            case 'time':
                return 'שעה';
            case 'comments':
                return 'הערות';
            case 'summary':
                return 'סיכום';
            case 'planupSystemStatus':
                return 'מצב פניה';

        }

        return field;

    },











    formatValueForEdit(table, value) {

        let formatted = '';


        for (let i = 0; i < value.length; i++) {

            let from = this.printSingleValue(value[i].field, value[i].from);

            let to = this.printSingleValue(value[i].field, value[i].to);

            if (!from)
                from = '';

            if (!to)
                to = '';


            formatted += `${this.fieldToHebrew(value[i].field)}: מ-"${from}" ל-"${to}"<br>`;
        }

        return formatted;

    },


    formatValue(action, table, value) {



        if (action == 2) {//if is comment
            return value.comment;
        }






        if (action) {//if is edit
            return this.formatValueForEdit(table, value);
        }


        //here we know the log is of creation of an object
        let formatted = '';

        let fields = Object.keys(value);

        for (let i = 0; i < fields.length; i++) {


            let field = fields[i];

            if (value[field] === null)
                continue;


            if (value[field].length == 0)
                continue;

            if (!Object.keys(value[field]).length)
                continue;


            if (this.fieldsToSkip.includes(field))
                continue;

            formatted += `${this.fieldToHebrew(field)}: ${this.printSingleValue(field, value[field])}<br> `

        }

        return formatted;

    },



    logTableToText(action, table) {



        switch (table) {
            case 'events':
                if (action == 0)
                    return;
                return 'אירוע'
            case 'customer':
                return 'לקוח';
            case 'customer_contact':
                return 'איש קשר';
            case 'document':
                return 'מסמך';
            case 'appointments':
                return 'פגישה';
        }

        return table;
    },


    fieldsToSkip: [
        'milestones', 'pic', 'errors', 'milestones', 'googleCalendarEventIds', 'milestones', 'pic', '0', 'id', 'lastEdit', 'created', 'customer', 'inGrideMode', 'presetLocked', 'strictTables', 'reservedSeatingAllowed', 'groupName', 'inGridMode',
        'resevedSeatingAllowed', 'guestsSeatingLimit', 'showTimer', 'akumPayment', 'groupSeating', 'hasSms', 'sorting', 'status', 'hasMultipleEvents', 'hide', 'isNotifyByMail'
    ],


}