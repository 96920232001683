







let MenuForBiz = [
    {
       name: 'dashboard',
       route: '/',
       service: 1
    },
    {
      name: 'calendar',
      route: '/calendar',
      service: 3
   },
    {
      name: 'customers',
      route: '/customers',
      service: 2
   },
   // {
   //    name: 'events',
   //    route: '/events',
   //    service: 15
   // },
   
   {
      name: 'tasks',
      route: '/tasks',
      service: 9999
   },   {
      name: 'accounting',
      route: '/accounting',
      service: 99999
   },
   // {
   //    name: 'billing',
   //    route: '/billing',
   //    service: 4
   // },
   //  {
   //    name: 'analytics',
   //    route: '/reports',
   //    service: 5
   // },
   {
      name: 'messages',
      route: '/messages',
      service: 54344234
   },
    {
      name: 'settings',
      service:  8,
      subMenu: [
         {
            name: 'orginizationSettings',
            service: 9,
            route: '/settings'
         },
         {
            name: 'milestones',
            service: 4545329,
            route: '/milestones'
         },
         
         {
            name: 'messageTemplates',
            service: 3332,
            route: '/messageTemplates'
         },
         
         {
            name: 'documents',
            service: 11,
            route: '/documents'
         },
         {
            name: 'parametersSettings',
            service: 10,
            route: '/parameters'
         }


      ]
   },
   {
      name:'log',
      route:'/log',
      service: 1337
   }    
   

 ];





 export default MenuForBiz;